import { createRouter, createWebHistory } from 'vue-router';
import LoginComponent from '../components/Login.vue';
import Callback from '../components/Callback.vue';  // Add a callback component
const routes = [
  {
    path: '/',
    name: 'Login',
    component: LoginComponent,
  },
  { path: '/callback', component: Callback }, 
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
