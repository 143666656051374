<template>
  <div class="callback-container">
    <div v-if="loading" class="loading-container">
      <h1>Logging you in, please wait...</h1>
      <div class="loading-spinner"></div> <!-- You can customize the spinner -->
    </div>
    <div v-else-if="error" class="error-container">
      <h1>Oops, something went wrong!</h1>
      <p>{{ errorMessage }}</p>
      <a href="https://support-sign-in.newscatcherapi.com">Return to Login</a>
    </div>
  </div>
</template>

<script>
import { useAuth0 } from '@auth0/auth0-vue';
import { ref, onMounted } from 'vue';
import axios from 'axios';

export default {
  name: 'CallbackComponent',
  setup() {
    const loading = ref(true);
    const error = ref(false);
    const errorMessage = ref('');

    const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();

    const handleLogin = async (userInfo) => {
      try {
        const response = await axios.get("https://support-api.newscatcherapi.xyz/login", {
          params: {
            email: userInfo.email,
            name: userInfo.name,
          },
          headers: {
            accept: "application/json",
            "x-api-token": "Ymndjh78a1PQ0",
          },
        });

        if (response.data.message === "Login successful") {
          const userData = response.data.data;
          if (typeof window.Atlas !== "undefined") {
            window.Atlas.call("identify", {
              userId: userData.user_id,
              email: userData.email,
              userHash: userData.user_hash,
              account: {
                name: userData.company_name,
                externalId: userData.account_id,
              },
              onComplete: () => {
                window.location.href = 'https://support.newscatcherapi.com/customer-portal';
              },
            });
          } else {
            console.error("Atlas SDK not found");
            errorMessage.value = "Internal system error. Please try again later.";
            error.value = true;
          }
        } else {
          console.error(response.data.message);
          errorMessage.value = response.data.message;
          error.value = true;
          window.location.href = 'https://support-sign-in.newscatcherapi.com';
        }
      } catch (err) {
        console.error("Error during login:", err);
        errorMessage.value = "Failed to login. Please try again.";
        error.value = true;
      }
      loading.value = false;
    };

    onMounted(async () => {
      if (isAuthenticated) {
        try {
          await getAccessTokenSilently({
            audience: 'https://myapp.com/api',
          });
          await handleLogin(user.value);
        } catch (err) {
          console.error('Error getting token:', err);
          errorMessage.value = "Unable to verify authentication. Please try again.";
          error.value = true;
          loading.value = false;
        }
      } else {
        console.log('User not authenticated');
        errorMessage.value = "You are not authenticated. Redirecting to login...";
        error.value = true;
        loading.value = false;
      }
    });

    return {
      loading,
      error,
      errorMessage,
    };
  },
};
</script>

<style scoped>
.callback-container {
  text-align: center;
  margin-top: 50px;
  font-family: 'Helvetica', Arial, sans-serif; /* Updated font */
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top-color: #000;
  border-radius: 50%;
  animation: spin 1s infinite linear;
}

.error-container a {
  color: #007bff;
  text-decoration: underline;
  font-family: 'Helvetica', Arial, sans-serif; /* Ensure links also use Helvetica */
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
